<script setup>
    import { ref, computed, defineProps } from 'vue';
    import $t from 'o365.modules.translate.ts';
    import API from 'o365.modules.data.api.ts';
    import dsOPerson from 'o365.modules.dataObjects.dsOPerson.ts';

    const props = defineProps({
        showTitle:{
            type:Boolean,
            default:false
        },
        is: String,
    });

    const currentThemeString = computed(() => {
        if (currentTheme.value == 'dark') {
            return $t("Dark theme");
        }
        return $t("Light theme");
    });

    let currentTheme = ref(document.documentElement.getAttribute('data-bs-theme') ?? 'light');

    async function changeTheme(event) {
        event.stopPropagation();
        event.preventDefault();

        currentTheme.value = currentTheme.value === 'light' ? 'dark' : 'light';
        
        document.documentElement.setAttribute('data-bs-theme', currentTheme.value);

        dsOPerson.current.ColorTheme = currentTheme.value;

        const themeChangeEvent = new CustomEvent('o365.theme.changed', {
            detail: {
                theme: currentTheme.value
            }
        });
        document.body.dispatchEvent(themeChangeEvent);

        await dsOPerson.save();

        await API.request({
            requestInfo: '/api/colortheme/set',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                'ColorTheme': currentTheme.value
            },
            responseBodyHandler: false
        });

        let frames = document.querySelectorAll('iframe');
        for (let i = 0; i < frames.length; i++) {
            if(frames[i].contentWindow.location.origin === window.location.origin){
                frames[i].contentWindow.location.reload();
            }
        }
    }

    const currentThemeIcon = computed(_ => currentTheme.value == 'dark' ? 'bi-brightness-high-fill' : 'bi-moon-stars-fill');
    const currentThemeTitle = computed(_ => currentTheme.value == 'dark' ? $t('Change to light color theme') : $t('Change to dark color theme'));
</script>

<template>
    <component v-if="is" :is="is" id="themeIndicator" @click="changeTheme($event)" :title="$t('Change theme')">
        <slot :currentThemeIcon="currentThemeIcon" :title="currentThemeTitle">
            <i :class="`bi ${currentThemeIcon}`" :title="currentThemeTitle"></i>
        </slot>
    </component>
    <a v-else id="themeIndicator" class="dropdown-item" @click="changeTheme($event)" style="cursor:pointer;" :title="$t('Change theme')">
        <i :class="`bi ${currentThemeIcon}`" :title="currentThemeTitle"></i>
        <span class="ms-2" v-if="showTitle">
            {{ currentThemeString }}
        </span>
    </a>  
</template>
